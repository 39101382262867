import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1013.000000 1132.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1132.000000) scale(0.100000,-0.100000)">


<path d="M4725 11303 c-1010 -84 -1868 -405 -2655 -992 -269 -201 -597 -507
-814 -761 -721 -843 -1148 -1875 -1235 -2989 -14 -174 -14 -608 0 -782 96
-1228 596 -2337 1454 -3227 110 -114 126 -134 112 -145 -8 -7 -228 -187 -487
-402 -259 -214 -496 -408 -525 -430 l-54 -40 0 -762 -1 -763 4565 0 4565 0 0
765 c0 723 -1 765 -18 765 -12 0 -1020 818 -1091 886 -2 2 52 61 119 131 806
838 1290 1859 1431 3018 26 219 37 739 20 965 -86 1128 -510 2160 -1237 3010
-135 158 -412 434 -569 566 -828 697 -1804 1100 -2869 1184 -117 9 -614 11
-711 3z m850 -97 c602 -68 1106 -214 1640 -476 532 -260 963 -574 1385 -1006
541 -555 942 -1216 1189 -1964 104 -313 188 -710 228 -1070 25 -234 25 -806 0
-1040 -129 -1171 -616 -2213 -1421 -3038 -867 -888 -1971 -1402 -3205 -1493
-318 -23 -769 -2 -1099 51 -1375 221 -2595 1020 -3374 2210 -445 679 -713
1435 -805 2270 -25 233 -25 805 0 1040 114 1042 510 1975 1178 2775 188 225
514 537 764 732 573 446 1279 775 1985 927 168 36 433 76 610 91 204 18 730
13 925 -9z m3458 -9239 c279 -230 507 -420 507 -423 0 -2 -510 -4 -1132 -4
l-1133 0 75 41 c434 237 769 470 1068 744 51 46 88 73 95 68 7 -4 240 -195
520 -426z m-7316 352 c94 -86 326 -272 443 -356 193 -137 316 -213 630 -387
l65 -36 -1125 0 c-990 0 -1123 2 -1110 15 29 29 1005 833 1012 834 4 1 43 -31
85 -70z m1309 -855 c497 -228 1089 -378 1668 -424 166 -13 576 -13 742 0 578
46 1172 197 1668 424 l79 36 1211 -2 1211 -3 3 -722 2 -723 -4525 0 -4525 0 0
725 0 725 1194 0 1194 0 78 -36z"/>
<path d="M4555 11119 c-134 -20 -236 -100 -251 -195 -3 -24 3 -176 15 -339 25
-360 31 -379 133 -431 123 -62 341 -44 478 39 44 26 55 38 54 57 -1 14 -7 112
-14 219 l-13 193 -141 -7 c-78 -4 -144 -9 -148 -12 -10 -5 -10 -45 -2 -107 l7
-47 41 3 41 3 0 -67 c0 -55 -4 -71 -21 -88 -43 -43 -127 -35 -160 16 -19 29
-43 260 -44 426 0 108 18 137 91 145 59 7 98 -24 108 -83 l6 -39 70 3 c39 1
86 5 105 8 l35 5 -7 87 c-3 48 -7 106 -7 128 l-1 42 -77 20 c-94 25 -217 34
-298 21z"/>
<path d="M5166 11059 c-7 -68 -6 -74 15 -90 28 -21 28 -42 4 -373 -18 -242
-20 -255 -50 -266 -21 -8 -24 -16 -27 -84 -2 -42 -3 -76 -3 -77 0 -2 650 -48
652 -45 3 3 34 429 31 431 -2 1 -46 5 -99 8 l-96 6 -8 -101 c-6 -89 -10 -104
-32 -126 -23 -23 -30 -24 -90 -19 -36 4 -68 9 -70 11 -9 8 36 576 47 596 6 11
17 20 24 20 18 0 26 32 26 102 0 67 9 64 -186 74 l-131 6 -7 -73z"/>
<path d="M6200 10949 c-45 -19 -90 -60 -112 -102 -33 -65 -188 -584 -188 -630
0 -51 32 -119 72 -152 75 -64 221 -115 327 -117 106 -1 177 35 215 110 13 26
60 170 106 320 71 234 82 280 78 327 -8 99 -87 178 -228 226 -73 25 -229 36
-270 18z m222 -210 c10 -5 25 -24 34 -41 13 -25 14 -38 4 -77 -21 -85 -112
-376 -127 -409 -44 -94 -193 -66 -193 35 0 52 122 448 146 474 34 38 84 45
136 18z"/>
<path d="M3250 10773 c-80 -30 -149 -59 -153 -63 -5 -4 22 -180 59 -391 57
-327 65 -387 55 -413 -9 -26 -8 -41 11 -95 12 -35 27 -65 33 -68 13 -5 271 92
268 101 -1 4 -12 36 -25 71 -18 52 -27 65 -44 65 -27 0 -50 26 -59 67 -7 36
-17 29 111 78 l61 24 22 -40 c21 -37 21 -40 5 -67 -15 -27 -15 -32 6 -91 13
-34 26 -63 30 -66 3 -2 73 22 154 53 142 54 147 57 143 82 -12 56 -47 120 -71
130 -28 10 -58 54 -279 400 -86 135 -162 253 -169 262 -12 16 -24 13 -158 -39z
m154 -376 c42 -65 76 -121 76 -125 0 -5 -26 -17 -58 -29 l-59 -21 -22 131
c-12 73 -25 143 -28 157 -8 37 5 21 91 -113z"/>
<path d="M6994 10645 c-32 -58 -35 -69 -24 -90 7 -13 9 -33 5 -46 -11 -37
-247 -478 -268 -500 -10 -10 -28 -19 -40 -19 -18 0 -30 -14 -55 -66 -18 -37
-30 -70 -25 -74 15 -15 376 -203 432 -226 104 -43 188 -28 255 47 98 107 124
262 56 324 -26 23 -26 38 0 30 30 -10 97 13 135 46 52 46 95 140 95 209 0 120
-52 177 -265 290 -55 29 -137 72 -182 97 l-82 44 -37 -66z m251 -256 c88 -47
106 -89 71 -158 -32 -60 -75 -65 -164 -19 l-62 31 48 89 c26 48 49 87 52 85 3
-1 28 -14 55 -28z m-162 -330 c31 -17 64 -40 72 -52 43 -62 -20 -187 -95 -187
-30 0 -150 57 -150 71 0 11 65 142 87 176 9 13 18 23 22 23 4 0 33 -14 64 -31z"/>
<path d="M2648 10477 c-53 -34 -97 -66 -98 -72 0 -5 19 -44 44 -85 39 -63 47
-88 57 -158 6 -46 9 -86 6 -89 -3 -3 -39 16 -81 41 -62 40 -82 59 -123 121
-26 41 -51 75 -55 75 -4 0 -59 -34 -123 -76 l-116 -75 17 -27 c61 -94 68 -102
88 -102 12 0 112 -54 221 -120 195 -117 201 -122 242 -185 49 -74 60 -109 43
-130 -9 -11 -3 -26 31 -77 23 -35 43 -64 44 -66 4 -5 265 167 265 175 0 3 -17
32 -37 64 -31 47 -44 59 -68 61 -25 3 -39 16 -84 82 l-54 79 -24 216 c-13 119
-21 233 -18 253 4 30 -2 47 -32 97 -21 34 -40 61 -43 61 -3 0 -48 -28 -102
-63z"/>
<path d="M7805 9953 c-398 -347 -406 -353 -436 -353 -22 0 -37 -12 -73 -59
-25 -33 -46 -62 -46 -65 0 -6 216 -176 225 -176 8 0 34 31 71 83 21 29 24 41
16 49 -7 7 -12 19 -12 28 0 17 49 70 64 70 5 0 40 -25 77 -55 l69 -56 -21 -40
c-18 -36 -24 -40 -51 -37 -28 3 -37 -3 -76 -54 -24 -31 -41 -61 -38 -66 8 -13
225 -182 241 -189 7 -2 33 22 58 53 37 47 44 63 40 88 -4 26 26 94 172 385 97
194 175 354 173 356 -2 1 -57 44 -123 95 -66 51 -125 96 -130 101 -6 4 -91
-63 -200 -158z m101 -235 c-29 -62 -59 -126 -67 -141 l-15 -28 -48 36 c-26 20
-45 42 -43 48 4 12 208 195 219 196 4 1 -17 -50 -46 -111z"/>
<path d="M4963 9995 c-14 -10 -24 -12 -28 -5 -11 17 -121 12 -140 -7 -13 -13
-18 -14 -25 -3 -12 19 -129 9 -145 -14 -9 -12 -15 -14 -20 -5 -11 17 -120 7
-143 -14 -12 -11 -22 -13 -28 -7 -17 17 -106 -1 -131 -26 -13 -13 -23 -19 -23
-14 0 29 -140 -1 -147 -31 -4 -15 -8 -17 -19 -8 -26 22 -144 -10 -144 -38 0
-10 -5 -11 -19 -4 -26 15 -122 -19 -137 -47 -8 -16 -13 -19 -22 -10 -16 16
-119 -18 -133 -43 -5 -11 -16 -15 -28 -12 -25 6 -106 -30 -122 -54 -7 -13 -20
-18 -38 -16 -30 4 -121 -49 -121 -71 0 -9 -8 -12 -25 -9 -30 6 -111 -39 -120
-66 -4 -13 -12 -17 -25 -14 -23 6 -108 -44 -116 -69 -3 -10 -15 -18 -28 -18
-28 0 -107 -55 -113 -80 -3 -10 -12 -16 -21 -14 -25 6 -112 -62 -112 -87 0
-15 -4 -19 -16 -15 -24 9 -114 -62 -114 -90 0 -17 -5 -21 -19 -17 -26 7 -114
-74 -108 -98 3 -12 0 -15 -13 -12 -23 6 -98 -61 -106 -93 -3 -13 -13 -24 -20
-24 -20 0 -94 -81 -94 -102 0 -10 -6 -18 -14 -18 -22 0 -96 -82 -96 -107 0
-14 -6 -23 -15 -23 -28 0 -103 -99 -90 -119 2 -5 -5 -12 -18 -15 -28 -8 -77
-77 -77 -110 0 -15 -6 -26 -13 -26 -29 0 -92 -95 -81 -123 3 -9 -3 -16 -15
-20 -24 -6 -71 -78 -71 -109 0 -13 -9 -29 -20 -36 -27 -17 -63 -90 -57 -118 3
-15 -2 -24 -14 -27 -23 -6 -72 -103 -64 -126 3 -12 0 -21 -10 -25 -21 -8 -66
-106 -59 -129 3 -10 -2 -24 -14 -35 -28 -25 -54 -99 -43 -120 7 -14 5 -21 -9
-30 -26 -16 -56 -110 -42 -133 9 -14 8 -19 -7 -24 -23 -7 -55 -110 -42 -134 6
-10 4 -19 -6 -26 -18 -14 -44 -118 -33 -135 5 -8 1 -24 -10 -39 -21 -31 -34
-110 -21 -130 7 -10 5 -19 -7 -27 -22 -16 -31 -144 -11 -144 9 -1 7 -6 -6 -15
-27 -21 -36 -141 -11 -151 15 -6 15 -8 -4 -21 -17 -12 -20 -25 -20 -78 0 -35
5 -67 10 -70 6 -4 4 -12 -5 -21 -21 -21 -21 -132 0 -140 12 -5 12 -9 -2 -30
-20 -31 -13 -122 11 -139 15 -12 15 -13 0 -19 -13 -5 -16 -18 -12 -72 3 -45 9
-70 20 -78 13 -10 14 -13 1 -25 -10 -9 -13 -24 -9 -44 3 -16 6 -40 6 -53 0
-12 7 -30 16 -39 13 -12 13 -19 4 -30 -15 -18 5 -126 26 -138 10 -6 11 -12 3
-25 -17 -27 7 -133 31 -139 15 -4 17 -9 8 -22 -15 -25 -1 -108 22 -131 11 -11
16 -27 13 -38 -7 -28 24 -129 41 -135 9 -4 12 -14 9 -26 -8 -31 26 -123 48
-128 14 -4 16 -9 9 -23 -13 -24 26 -127 50 -135 11 -4 16 -13 13 -28 -6 -31
33 -118 56 -124 12 -3 17 -11 14 -27 -7 -35 43 -126 68 -126 19 0 20 -2 8 -16
-11 -14 -9 -24 16 -70 16 -30 38 -57 48 -60 11 -3 16 -12 13 -22 -7 -22 50
-113 75 -119 13 -4 18 -11 14 -22 -7 -22 52 -112 77 -119 10 -2 18 -13 18 -24
0 -32 60 -108 85 -108 18 0 21 -5 18 -23 -5 -27 60 -107 88 -107 14 0 19 -7
19 -25 0 -31 65 -105 91 -105 12 0 19 -7 19 -20 0 -29 68 -100 96 -100 17 0
22 -4 17 -16 -8 -22 83 -107 108 -101 15 4 19 0 19 -18 0 -29 85 -98 112 -89
13 4 16 1 12 -10 -4 -10 8 -28 33 -51 40 -37 74 -52 93 -40 6 4 11 1 9 -6 -3
-28 2 -36 47 -68 28 -21 52 -31 65 -28 14 4 19 0 19 -14 0 -22 83 -79 116 -79
13 0 24 -8 27 -19 5 -22 102 -75 123 -67 7 3 16 -4 19 -15 8 -24 101 -73 126
-66 11 3 20 -1 24 -12 7 -23 98 -65 124 -58 14 3 22 -1 26 -13 5 -17 89 -60
117 -60 8 0 19 5 26 12 9 9 12 7 12 -9 0 -25 89 -67 127 -60 15 3 27 -1 30 -9
7 -18 98 -47 129 -41 16 3 28 -1 31 -9 8 -22 113 -49 134 -35 14 8 20 7 27 -5
10 -18 109 -46 135 -38 9 3 17 11 18 17 0 7 4 2 9 -10 11 -27 107 -51 138 -34
15 8 23 7 32 -4 15 -18 118 -31 139 -18 12 7 20 6 29 -3 7 -8 29 -15 50 -16
20 0 44 -2 52 -3 8 -1 23 3 34 8 12 6 22 5 29 -2 14 -14 134 -15 142 -1 4 6
16 4 31 -6 30 -20 141 -15 154 7 7 11 12 11 22 1 18 -18 122 -16 138 3 10 12
16 13 27 4 20 -17 134 -5 145 16 8 13 13 14 24 4 21 -17 125 -5 141 16 8 12
16 14 28 7 24 -13 117 5 135 27 12 13 20 15 32 8 25 -16 129 10 135 33 4 15 8
17 23 8 29 -18 121 10 137 42 7 14 13 19 13 13 0 -30 135 5 145 38 5 14 10 16
23 8 23 -14 113 21 127 49 8 15 15 18 27 11 21 -12 125 32 134 56 4 9 13 14
21 10 20 -7 122 45 128 66 4 10 13 15 28 12 32 -6 117 51 117 78 0 17 3 19 14
10 7 -6 22 -8 32 -4 37 13 93 56 99 74 4 11 12 16 21 12 23 -9 114 58 114 83
0 17 5 20 23 17 27 -5 107 49 114 78 3 10 13 19 22 19 26 0 101 62 108 87 3
14 13 23 24 23 26 0 99 68 99 91 0 12 7 19 20 19 30 0 100 68 100 96 0 17 6
24 20 24 27 0 102 80 96 102 -3 12 2 18 14 18 26 0 103 87 97 111 -4 14 0 19
13 19 25 0 90 78 90 107 0 13 6 23 15 23 23 0 87 91 82 117 -3 17 1 23 13 23
31 0 86 86 77 121 -4 14 0 19 14 19 24 0 81 87 76 117 -3 14 2 23 14 26 23 6
72 103 64 128 -4 13 -2 19 9 19 21 0 69 96 63 126 -3 16 0 24 10 24 19 0 66
104 58 130 -4 13 -1 22 8 26 9 3 25 30 38 61 17 43 20 59 11 73 -9 15 -7 19 8
23 26 7 59 111 43 137 -10 15 -9 19 7 23 25 6 57 113 42 137 -7 12 -6 19 7 27
24 15 47 119 31 138 -10 12 -9 16 3 21 21 8 42 109 30 137 -7 15 -5 25 9 39
24 24 29 128 7 137 -6 1 -2 9 7 16 21 15 33 130 16 147 -8 8 -6 15 4 25 18 19
20 139 2 145 -9 3 -8 9 5 22 22 22 24 118 2 139 -13 13 -13 17 1 28 22 18 15
126 -10 144 -15 12 -15 13 0 19 13 5 16 17 12 66 -2 35 -11 67 -20 77 -14 15
-14 20 -2 31 23 21 0 142 -27 142 -5 0 -3 10 5 23 12 18 13 32 4 72 -5 28 -16
57 -24 66 -10 11 -11 18 -3 26 17 17 -8 120 -32 137 -15 10 -17 16 -9 21 25
15 -8 145 -37 145 -10 0 -11 4 -2 18 17 27 -15 132 -42 139 -15 4 -17 8 -8 20
16 19 -18 134 -41 140 -11 3 -15 13 -12 31 4 32 -31 120 -52 128 -8 3 -12 13
-9 24 7 25 -42 130 -61 130 -9 0 -12 7 -9 19 6 25 -44 122 -66 128 -13 3 -17
12 -13 28 2 14 -7 42 -23 69 -25 42 -35 51 -58 47 -4 -1 -5 10 -1 23 6 27 -33
98 -66 118 -11 7 -20 22 -20 34 0 26 -66 114 -85 114 -8 0 -12 6 -9 13 7 21
-59 111 -85 115 -12 2 -21 9 -20 15 3 27 -3 39 -37 77 -20 22 -44 40 -54 40
-13 0 -17 5 -13 20 7 25 -79 115 -101 107 -9 -4 -12 0 -9 13 6 21 -75 110
-100 110 -9 0 -17 7 -17 17 0 22 -76 93 -100 93 -11 0 -20 7 -20 15 0 23 -81
95 -106 95 -14 0 -24 8 -28 23 -7 27 -91 91 -110 84 -9 -4 -16 3 -20 16 -7 29
-97 90 -119 81 -12 -4 -17 -1 -17 13 0 24 -94 86 -119 78 -13 -4 -20 0 -24 14
-6 24 -79 71 -109 71 -13 0 -29 9 -36 20 -17 27 -90 63 -118 57 -15 -3 -24 2
-27 14 -6 23 -103 72 -126 64 -12 -3 -21 0 -25 10 -8 22 -93 58 -122 52 -17
-3 -28 1 -34 13 -12 23 -92 54 -123 48 -16 -3 -28 1 -35 12 -14 23 -109 52
-131 41 -13 -7 -20 -5 -29 9 -15 24 -115 48 -135 32 -10 -8 -16 -6 -25 8 -15
24 -117 47 -137 30 -11 -9 -18 -7 -33 8 -26 26 -123 39 -136 19 -8 -13 -12
-12 -25 5 -20 24 -133 38 -146 19 -5 -9 -10 -8 -18 2 -18 22 -122 32 -139 14
-11 -12 -16 -13 -28 -2 -22 19 -105 25 -134 10 -21 -11 -27 -11 -37 1 -16 19
-108 18 -136 -1z m230 -73 c37 -3 62 0 72 8 12 10 18 10 30 0 8 -6 41 -14 74
-17 51 -5 62 -3 67 11 6 15 8 15 20 -2 17 -25 117 -40 140 -21 12 10 17 9 27
-4 18 -24 111 -38 137 -20 17 12 20 12 20 -1 0 -10 19 -21 52 -31 61 -18 84
-19 93 -4 5 7 12 4 20 -9 18 -25 119 -50 138 -33 12 9 16 8 20 -8 6 -24 114
-58 137 -43 11 6 17 4 25 -11 13 -26 113 -61 131 -46 9 7 14 5 19 -9 7 -23
107 -67 130 -58 8 3 19 -2 24 -13 12 -21 107 -65 125 -58 7 3 18 -3 24 -14 16
-25 107 -70 126 -63 10 4 16 1 16 -10 0 -19 99 -78 122 -72 10 2 18 -4 21 -15
6 -23 97 -81 119 -76 10 3 18 -3 22 -17 7 -25 76 -76 104 -76 12 0 23 -9 27
-20 10 -30 94 -92 117 -84 13 4 16 1 12 -9 -7 -19 75 -97 103 -97 16 0 23 -6
23 -19 0 -24 87 -104 106 -97 9 3 14 -1 14 -14 0 -29 68 -100 95 -100 17 0 22
-5 19 -17 -6 -25 74 -116 96 -110 12 3 16 0 13 -12 -6 -23 63 -111 87 -111 15
0 20 -7 20 -26 0 -30 63 -114 85 -114 7 0 11 -7 9 -17 -6 -24 61 -113 85 -113
14 0 17 -6 14 -25 -7 -31 45 -115 70 -115 14 0 17 -5 12 -20 -8 -25 41 -117
65 -125 12 -4 16 -12 13 -24 -6 -23 41 -116 63 -125 9 -4 14 -12 10 -21 -7
-20 39 -124 59 -131 10 -4 13 -13 10 -25 -9 -26 39 -129 60 -129 12 0 13 -3 4
-18 -15 -24 13 -116 41 -135 12 -8 16 -18 10 -26 -10 -18 17 -112 37 -128 9
-7 13 -19 9 -29 -11 -27 15 -124 36 -135 14 -8 16 -13 7 -27 -15 -24 6 -128
28 -136 12 -5 13 -9 4 -20 -17 -20 -3 -114 20 -137 10 -10 14 -21 9 -24 -18
-11 -12 -111 9 -136 16 -19 16 -25 5 -37 -17 -17 -8 -121 11 -137 10 -9 10
-15 -2 -30 -16 -22 -10 -119 8 -142 7 -7 6 -14 -1 -18 -13 -8 -13 -132 0 -140
7 -4 5 -14 -5 -28 -21 -30 -21 -135 0 -144 13 -4 12 -8 -6 -21 -19 -13 -21
-22 -17 -88 2 -41 0 -75 -5 -77 -4 -1 -12 -28 -16 -60 -6 -43 -4 -62 6 -77 12
-17 12 -20 0 -20 -8 0 -20 -14 -26 -31 -14 -39 -14 -119 -1 -119 5 0 -1 -10
-14 -23 -25 -25 -44 -113 -28 -129 6 -6 1 -18 -13 -33 -24 -23 -42 -101 -31
-130 3 -8 1 -15 -5 -15 -18 0 -51 -90 -47 -126 3 -21 -1 -35 -9 -39 -18 -6
-55 -94 -51 -121 2 -13 -5 -29 -14 -36 -25 -19 -58 -110 -47 -130 6 -12 4 -17
-11 -21 -28 -7 -72 -104 -59 -129 8 -15 6 -18 -10 -18 -23 0 -75 -89 -69 -118
2 -9 -6 -22 -17 -27 -24 -13 -65 -82 -65 -112 0 -12 -8 -24 -17 -27 -22 -7
-73 -86 -73 -113 0 -12 -8 -23 -19 -26 -26 -6 -84 -94 -78 -118 4 -14 0 -19
-13 -19 -28 0 -92 -80 -87 -107 3 -19 0 -23 -19 -23 -24 0 -94 -79 -94 -106 0
-8 -6 -14 -13 -14 -7 0 -32 -20 -56 -44 -31 -31 -42 -50 -39 -64 4 -15 1 -18
-12 -15 -23 6 -110 -72 -110 -98 0 -11 -7 -19 -16 -19 -26 0 -104 -69 -104
-91 0 -13 -7 -19 -21 -19 -25 0 -109 -67 -109 -86 0 -8 -8 -14 -17 -14 -28 -1
-113 -68 -113 -89 0 -14 -5 -18 -19 -14 -24 6 -107 -49 -116 -77 -3 -11 -12
-17 -20 -14 -18 7 -102 -42 -116 -67 -5 -10 -20 -19 -32 -19 -29 0 -117 -55
-117 -73 0 -10 -8 -13 -24 -10 -27 6 -101 -29 -118 -56 -7 -11 -18 -16 -30
-13 -25 6 -116 -36 -123 -58 -4 -12 -13 -15 -33 -13 -33 5 -122 -31 -122 -50
0 -9 -10 -12 -34 -9 -35 4 -126 -29 -126 -47 0 -5 -9 -7 -20 -4 -30 8 -120
-17 -133 -38 -8 -13 -15 -15 -24 -8 -17 15 -122 -10 -136 -32 -8 -13 -15 -15
-23 -8 -18 15 -114 -1 -134 -21 -12 -12 -21 -15 -28 -8 -14 14 -120 -1 -138
-19 -11 -11 -18 -12 -28 -4 -18 15 -115 4 -138 -16 -11 -10 -18 -11 -23 -4
-10 17 -117 13 -142 -4 -16 -11 -22 -12 -25 -3 -6 17 -122 18 -152 3 -18 -10
-27 -9 -41 1 -24 17 -117 18 -133 2 -10 -10 -15 -10 -22 1 -11 18 -127 28
-142 13 -8 -8 -15 -7 -24 3 -19 19 -120 31 -138 16 -10 -8 -16 -8 -25 3 -19
23 -118 41 -137 25 -13 -11 -18 -10 -25 3 -13 24 -115 45 -140 30 -15 -10 -19
-9 -19 2 0 20 -58 41 -112 41 -27 0 -48 4 -48 8 0 14 -93 48 -122 45 -16 -2
-32 2 -36 9 -12 19 -105 55 -128 49 -14 -4 -24 -1 -28 9 -8 21 -95 57 -123 52
-15 -3 -24 2 -28 13 -7 22 -98 64 -123 58 -14 -4 -22 0 -25 12 -6 24 -102 73
-128 65 -14 -5 -19 -2 -19 12 0 24 -86 76 -117 70 -17 -3 -23 0 -23 15 0 24
-87 81 -117 75 -16 -3 -23 1 -25 17 -4 27 -86 89 -110 83 -12 -4 -18 0 -18 13
0 22 -82 90 -109 90 -12 0 -21 6 -21 14 0 24 -89 98 -112 93 -14 -4 -18 -2
-14 9 8 22 -71 104 -100 104 -16 0 -22 4 -18 14 7 19 -73 106 -97 106 -12 0
-19 7 -19 18 0 26 -68 102 -91 102 -13 0 -19 7 -19 21 0 29 -66 109 -90 109
-13 0 -17 5 -13 18 6 24 -56 106 -84 110 -15 2 -19 8 -16 22 7 26 -53 120 -77
120 -13 0 -17 5 -13 20 7 26 -53 120 -77 120 -13 0 -16 6 -13 25 6 28 -37 109
-63 119 -9 3 -14 12 -11 20 9 22 -40 117 -63 123 -15 4 -18 11 -14 28 6 26
-31 121 -51 128 -7 3 -13 17 -12 30 0 40 -34 116 -54 122 -14 5 -17 11 -10 24
11 21 -20 120 -42 132 -9 5 -13 18 -10 33 7 34 -25 126 -43 126 -12 0 -12 4
-4 20 14 26 -12 130 -35 143 -12 7 -13 12 -5 22 15 18 -4 118 -26 136 -10 8
-11 15 -3 25 14 17 -6 125 -27 141 -11 8 -12 13 -3 18 14 10 6 119 -11 140 -8
9 -8 19 -1 33 12 22 -1 134 -17 140 -7 2 -6 12 3 29 15 29 9 123 -9 135 -8 5
-7 11 2 20 15 15 16 134 2 143 -7 4 -5 14 5 28 17 25 21 132 4 142 -7 5 -6 12
4 23 20 24 30 119 13 134 -10 9 -9 15 4 28 21 21 34 118 18 137 -8 10 -7 17 5
26 8 7 20 36 26 65 14 65 14 58 -5 76 -14 14 -14 16 0 16 20 0 32 25 41 83 5
30 3 52 -4 61 -9 11 -7 15 7 18 23 6 56 112 42 137 -6 12 -3 20 10 29 26 16
53 108 39 133 -8 15 -6 19 6 19 20 0 57 84 53 119 -2 17 4 32 17 42 23 19 60
113 50 129 -3 5 1 13 10 16 21 8 68 102 62 124 -2 9 4 22 14 28 24 15 70 107
63 125 -3 9 2 16 14 19 23 6 75 96 69 119 -3 11 2 19 14 22 24 6 81 97 75 120
-3 12 2 17 14 17 22 0 80 79 80 109 0 12 7 21 15 21 20 0 97 99 91 117 -3 7 1
13 10 13 23 0 94 81 94 107 0 16 6 23 19 23 24 0 104 87 97 106 -4 9 2 14 16
14 29 0 98 68 98 96 0 16 5 21 18 17 23 -6 117 80 109 100 -4 10 0 13 12 10
23 -6 111 63 111 87 0 14 7 20 23 20 29 0 108 55 114 80 3 10 10 16 17 14 20
-7 111 58 114 82 2 16 8 20 26 17 29 -6 106 41 113 68 4 14 11 18 24 14 23 -8
117 43 124 66 4 11 13 15 24 12 23 -6 116 41 125 63 4 10 12 14 22 10 20 -8
112 32 124 54 6 11 17 16 32 13 29 -6 116 31 124 52 4 10 13 13 26 9 30 -9
106 16 127 42 13 16 21 20 29 12 14 -14 108 9 130 33 11 11 22 15 31 10 19
-12 122 14 133 34 7 12 13 13 23 5 18 -15 126 4 140 24 7 9 16 11 26 5 22 -12
117 1 135 18 9 10 19 11 31 4 24 -12 114 -2 135 16 11 10 18 11 23 4 9 -16
122 -14 137 3 11 11 16 11 33 0 26 -17 134 -7 142 13 4 11 8 10 18 -3 8 -11
31 -19 68 -22z"/>
<path d="M4848 9849 c-1112 -64 -2153 -667 -2782 -1614 -332 -500 -522 -1014
-602 -1630 -25 -192 -26 -669 -1 -865 135 -1062 666 -1965 1522 -2586 317
-231 735 -431 1120 -538 971 -269 1994 -122 2850 412 804 500 1396 1316 1624
2237 82 331 105 531 105 910 0 312 -9 424 -55 670 -96 519 -312 1035 -610
1459 -354 501 -806 893 -1346 1167 -443 225 -906 349 -1413 379 -181 11 -211
11 -412 -1z m537 -89 c819 -77 1568 -432 2161 -1024 585 -586 939 -1319 1041
-2156 21 -176 24 -608 5 -775 -69 -603 -250 -1116 -565 -1601 -132 -204 -188
-279 -333 -444 -495 -567 -1207 -980 -1938 -1125 -269 -53 -351 -60 -691 -60
-334 0 -425 8 -670 56 -924 184 -1748 740 -2270 1534 -198 302 -320 555 -428
890 -123 383 -172 699 -172 1115 0 140 6 305 13 365 61 532 213 1006 465 1445
263 460 663 892 1101 1189 483 328 1056 536 1626 590 126 12 529 13 655 1z"/>
<path d="M4985 9430 c-13 -25 -15 -138 -15 -889 0 -747 2 -866 15 -899 16 -39
56 -70 165 -129 30 -16 75 -42 100 -58 25 -16 70 -42 100 -58 30 -17 84 -49
120 -72 36 -23 79 -50 95 -59 17 -9 44 -25 60 -36 17 -10 41 -25 55 -33 68
-37 106 -61 139 -83 20 -13 40 -24 45 -24 5 0 17 -6 25 -14 9 -7 47 -30 84
-50 99 -53 117 -66 117 -87 0 -10 -10 -24 -22 -30 -20 -11 -62 -41 -150 -109
-30 -23 -39 -70 -13 -70 14 0 134 73 145 88 3 4 29 24 58 45 51 36 95 47 107
27 4 -6 21 -17 38 -25 18 -9 61 -33 97 -55 36 -21 79 -46 95 -54 17 -9 42 -24
57 -33 52 -34 188 -113 194 -113 3 0 17 -9 31 -20 32 -25 58 -25 82 -1 11 11
29 25 41 31 l21 11 -4 797 c-2 551 -7 809 -15 837 -6 22 -15 62 -19 90 -7 45
-14 54 -68 98 -33 27 -73 55 -90 63 -49 23 -139 75 -156 90 -8 8 -19 14 -22
14 -4 0 -70 37 -147 83 -78 45 -155 90 -173 100 -18 10 -44 25 -59 35 -28 19
-146 88 -190 112 -99 55 -228 132 -233 140 -3 6 -13 10 -21 10 -9 0 -34 13
-57 28 -23 16 -111 69 -195 118 -84 49 -170 99 -190 111 -20 12 -50 29 -67 38
-16 8 -48 26 -70 40 -56 34 -90 33 -110 -5z m110 -70 c11 -9 41 -27 67 -39 25
-12 52 -29 58 -37 7 -7 18 -14 25 -14 7 0 18 -7 25 -15 7 -8 27 -17 46 -21 19
-3 34 -12 34 -20 0 -8 6 -14 14 -14 15 0 87 -38 96 -51 3 -4 21 -14 40 -23 53
-25 94 -48 100 -56 3 -4 32 -22 65 -40 115 -63 140 -77 215 -122 41 -25 106
-63 144 -84 38 -21 85 -49 105 -61 20 -12 50 -29 66 -38 17 -9 44 -24 60 -35
17 -11 41 -25 55 -32 25 -12 109 -60 290 -166 52 -30 104 -63 115 -72 11 -9
27 -20 36 -23 11 -3 18 -23 23 -61 7 -65 9 -1684 1 -1696 -8 -13 -59 5 -107
38 -24 16 -67 42 -96 58 -29 16 -68 38 -87 49 -145 85 -193 112 -215 123 -14
7 -37 20 -52 30 -14 9 -64 39 -110 66 -45 27 -135 80 -198 118 -138 81 -134
79 -285 164 -66 37 -122 71 -125 75 -3 3 -32 21 -65 38 -33 17 -73 40 -90 51
-16 11 -43 27 -60 35 -16 9 -43 25 -60 35 -16 11 -46 27 -65 36 -19 9 -37 19
-40 23 -3 3 -24 19 -47 34 -24 15 -45 37 -48 50 -10 38 4 1612 14 1719 1 10
32 -2 56 -22z"/>
<path d="M5075 9300 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6054 8115 c-16 -13 -37 -32 -47 -40 -24 -21 -18 -89 9 -110 10 -8
36 -28 58 -45 21 -16 45 -30 52 -30 8 0 14 -4 14 -9 0 -5 14 -14 30 -21 17 -7
30 -15 30 -19 0 -4 12 -12 28 -19 15 -7 44 -22 64 -35 20 -12 70 -42 110 -66
40 -24 96 -59 125 -77 28 -19 63 -34 78 -34 l27 0 -5 73 c-5 58 -13 85 -42
137 -20 36 -53 95 -73 132 -20 37 -46 78 -57 93 l-20 26 -76 -75 c-84 -83
-104 -89 -131 -36 -9 18 -21 36 -26 39 -5 3 -17 21 -26 39 -9 17 -21 32 -26
32 -5 0 -14 16 -21 35 -15 42 -35 44 -75 10z"/>
<path d="M3445 8174 c-167 -23 -374 -74 -438 -107 -40 -21 -43 -26 -74 -122
-31 -96 -32 -103 -23 -180 6 -44 10 -98 10 -121 0 -23 7 -55 15 -70 8 -16 15
-45 15 -64 0 -19 6 -47 14 -61 7 -14 16 -38 20 -54 4 -16 18 -39 32 -52 34
-31 167 -63 265 -63 68 0 81 3 106 24 l28 24 0 136 c0 143 5 160 46 154 19 -2
26 -11 32 -43 15 -70 22 -88 40 -99 32 -19 50 18 42 86 -6 55 -5 58 32 99 33
37 38 48 38 95 1 58 -3 52 79 109 16 11 38 36 49 55 27 49 17 115 -26 172 -50
65 -86 82 -186 84 -47 1 -99 0 -116 -2z"/>
<path d="M4523 7266 c-70 -36 -172 -97 -179 -107 -3 -5 -12 -9 -21 -9 -9 0
-34 -15 -57 -34 -23 -18 -51 -36 -63 -40 -13 -4 -23 -11 -23 -16 0 -5 -9 -12
-20 -15 -29 -9 -100 -57 -100 -67 0 -4 -9 -8 -19 -8 -11 0 -23 -5 -27 -11 -5
-9 4 -10 32 -4 58 12 78 10 131 -16 47 -23 52 -25 73 -28 5 0 7 -7 4 -15 -3
-8 4 -21 16 -29 14 -10 20 -23 18 -38 -3 -22 -14 -29 -75 -45 -7 -2 -13 -17
-13 -33 0 -21 -6 -32 -20 -36 -19 -6 -29 -34 -12 -36 4 0 20 -2 36 -3 17 -1
37 -12 50 -29 16 -19 27 -25 44 -21 28 8 70 -19 83 -53 6 -16 14 -23 24 -19 9
3 24 -3 35 -14 11 -11 29 -20 39 -20 10 0 21 -7 25 -15 3 -8 16 -15 29 -15 16
0 28 -9 35 -25 7 -16 17 -23 26 -19 9 3 23 -1 31 -10 9 -9 13 -16 8 -16 -13
-1 84 -48 104 -51 36 -5 62 12 74 48 10 32 10 38 -7 49 -11 7 -23 17 -29 22
-5 4 -18 11 -27 15 -10 3 -18 12 -18 18 0 18 70 3 85 -18 10 -13 25 -17 59
-15 28 1 46 -3 46 -9 0 -11 19 -9 95 6 24 5 35 1 54 -19 14 -14 30 -26 37 -26
6 0 18 -7 25 -17 8 -9 29 -22 47 -30 30 -12 44 -33 23 -33 -5 0 -23 -12 -40
-26 -26 -21 -39 -25 -77 -22 -50 3 -52 1 -72 -60 -8 -24 -7 -35 1 -42 51 -43
92 -70 106 -70 9 0 36 -14 60 -30 24 -17 50 -30 56 -30 7 0 15 -6 18 -14 6
-16 141 -86 165 -86 8 0 40 16 72 35 32 19 61 35 64 35 4 0 30 15 59 33 29 18
73 43 100 56 26 13 45 26 42 31 -3 4 9 13 25 19 17 5 33 17 36 26 3 8 14 15
23 16 9 0 39 13 66 29 28 15 69 38 92 50 28 16 47 35 59 61 16 38 16 40 -2 60
-10 11 -32 27 -49 35 -16 8 -55 30 -85 49 -30 19 -65 39 -78 44 -24 11 -167
95 -210 124 -15 9 -43 24 -62 33 -19 9 -48 26 -65 39 -16 12 -57 36 -90 54
-33 17 -73 40 -90 51 -16 10 -51 30 -76 44 -26 13 -110 62 -188 107 -78 46
-175 101 -214 122 -40 21 -85 47 -102 57 -16 11 -41 25 -55 32 -14 7 -36 19
-50 28 -58 37 -73 39 -117 16z m131 -101 c-4 -8 -7 -15 -9 -15 -2 0 -5 7 -9
15 -3 9 0 15 9 15 9 0 12 -6 9 -15z m-89 -55 c18 -7 19 -8 3 -15 -22 -9 -51 1
-43 15 7 12 13 12 40 0z m195 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10
10 16 10 5 0 9 -4 9 -10z m-255 -80 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0
6 4 10 9 10 6 0 13 -4 16 -10z m50 -26 c13 -5 14 -8 3 -15 -20 -13 -38 -11
-38 6 0 16 9 19 35 9z m192 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m68 -34 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5
0 12 -4 15 -10z m180 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10
6 0 13 -4 16 -10z m-523 -42 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3
12 -12z m438 -13 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
10 -7 10 -15z m-540 -20 c0 -16 -35 -21 -44 -6 -8 12 4 21 26 21 10 0 18 -7
18 -15z m174 6 c8 -13 -3 -19 -24 -14 -11 3 -17 9 -14 14 7 11 31 12 38 0z
m180 0 c3 -4 3 -11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z m-99 -41 c3 -6
-1 -13 -10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m185 1 c0 -14 -18 -23
-30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m185 -1 c3 -5 -3 -10 -14 -10 -12
0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m-555 -20 c0 -11 -3 -20 -8
-20 -4 0 -13 9 -20 20 -11 18 -10 20 8 20 13 0 20 -7 20 -20z m640 -20 c0 -5
-9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m180 0
c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z
m-730 -36 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z m175 6 c3
-5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-345
-20 c0 -23 -8 -25 -32 -6 -25 18 -22 26 7 26 18 0 25 -5 25 -20z m990 10 c0
-5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-735
-29 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m182 -18 c-3
-3 -11 -2 -17 2 -9 6 -9 10 1 16 14 8 27 -7 16 -18z m373 17 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-735 -31 c10 -15 -1
-23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m620 -9 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-357 -41 c-16 -10 -33 5
-23 21 5 8 11 8 21 -1 12 -10 12 -14 2 -20z m-188 1 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m110 -40 c6 -11 17 -20 25
-20 8 0 15 -4 15 -8 0 -5 12 -14 26 -20 14 -7 22 -17 19 -22 -10 -16 -30 -12
-55 10 -13 11 -29 20 -36 20 -7 0 -20 10 -28 22 -12 17 -13 24 -3 30 19 12 25
10 37 -12z m165 10 c18 -8 17 -9 -7 -9 -16 -1 -28 4 -28 9 0 12 8 12 35 0z
m170 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m215 0 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21
-10z m-120 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m180 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m83 -37 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m184 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-552 -24 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m555 -80 c12
-7 12 -12 3 -21 -9 -9 -16 -9 -27 1 -26 21 -5 39 24 20z m177 -6 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-110 -40 c10 -27 -11 -31 -36 -7
l-24 23 27 0 c15 0 29 -7 33 -16z m-179 -25 c-2 -6 -8 -10 -13 -10 -5 0 -11 4
-13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-88 -48 c0 -5 -9 -7 -20 -4 -11 3
-20 7 -20 9 0 2 9 4 20 4 11 0 20 -4 20 -9z m180 -1 c-20 -13 -40 -13 -40 0 0
6 12 10 28 10 21 0 24 -2 12 -10z m-96 -71 c-3 -5 -10 -7 -15 -3 -5 3 -7 10
-3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-184 1 c0 -5 -6 -10 -14 -10 -8 0 -18
5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z"/>
<path d="M4053 6414 c-11 -28 -8 -72 10 -159 9 -44 21 -129 28 -190 6 -60 15
-118 20 -127 16 -30 60 -21 114 22 28 21 64 47 80 56 35 19 102 70 128 96 10
10 24 18 31 18 15 0 26 21 26 51 0 13 -10 24 -30 30 -16 6 -30 15 -30 19 0 5
-13 11 -30 15 -16 3 -40 15 -52 25 -13 10 -63 38 -111 64 -48 25 -108 57 -132
72 -42 24 -45 25 -52 8z"/>
<path d="M6204 6076 c-10 -25 3 -33 59 -40 73 -9 109 0 105 28 -3 19 -10 21
-81 24 -61 2 -78 0 -83 -12z"/>
<path d="M4760 5975 c-19 -14 -64 -45 -100 -68 -36 -23 -76 -50 -90 -60 -14
-10 -77 -51 -140 -92 -63 -40 -141 -93 -173 -117 -32 -24 -83 -58 -112 -76
-56 -34 -60 -39 -69 -112 -10 -70 -124 -187 -236 -242 -67 -32 -128 -56 -161
-63 -19 -3 -45 -10 -59 -15 -14 -5 -37 -11 -52 -14 -24 -5 -28 -11 -28 -39 0
-17 5 -51 10 -74 6 -24 17 -87 25 -140 23 -160 36 -237 44 -263 10 -38 55 -55
141 -55 84 1 107 10 308 119 62 33 125 67 140 75 15 8 33 20 40 28 7 7 17 13
21 13 8 0 79 40 129 72 15 10 45 27 67 39 22 12 54 32 72 45 17 13 34 24 36
24 3 0 45 25 94 55 94 58 126 65 171 40 15 -8 46 -19 69 -25 34 -9 43 -16 53
-44 13 -42 -1 -112 -27 -131 -10 -8 -58 -37 -108 -65 -49 -28 -101 -57 -115
-65 -14 -8 -62 -35 -107 -60 -44 -25 -84 -53 -88 -63 -11 -28 -23 -445 -14
-470 12 -32 11 -89 -2 -136 -11 -38 -10 -41 28 -76 44 -43 112 -80 144 -80 12
0 44 14 71 30 26 17 51 30 53 30 3 0 28 16 55 35 28 19 60 36 73 38 20 2 22
-1 19 -48 -1 -27 0 -93 4 -145 6 -91 7 -95 40 -126 52 -47 138 -88 170 -80 14
4 62 30 107 58 45 27 134 75 197 105 63 30 122 58 130 64 8 5 24 13 35 18 32
15 103 94 110 123 6 23 2 33 -25 58 -53 49 -96 57 -214 37 -55 -9 -116 -17
-133 -17 -39 0 -41 10 -19 134 91 524 136 1082 106 1311 -6 50 -15 123 -19
162 -10 91 -34 139 -83 160 -21 9 -38 22 -38 29 0 21 -47 25 -84 7 -30 -14
-39 -15 -60 -3 -21 11 -30 10 -59 -4 -19 -9 -50 -16 -67 -16 l-33 0 6 54 c5
51 4 55 -20 60 -13 4 -48 22 -77 42 -73 48 -111 53 -156 19z m206 -1446 c7
-19 13 -82 13 -140 1 -112 -7 -135 -46 -127 -16 3 -18 14 -17 78 2 123 15 231
27 227 6 -2 16 -19 23 -38z"/>
<path d="M6006 5185 c-13 -19 -14 -30 -6 -45 19 -35 80 -17 80 23 0 49 -47 63
-74 22z"/>
<path d="M6002 4798 c-6 -6 -12 -22 -12 -35 0 -20 5 -23 40 -23 44 0 61 20 43
49 -13 20 -54 26 -71 9z"/>
<path d="M6003 4404 c-7 -3 -13 -16 -13 -29 0 -20 7 -25 36 -31 41 -8 57 4 52
37 -2 19 -10 24 -33 26 -16 1 -36 0 -42 -3z"/>
<path d="M6008 4009 c-23 -13 -23 -24 1 -48 26 -26 71 -17 71 15 0 13 -9 28
-19 34 -24 12 -31 12 -53 -1z"/>
<path d="M1756 9817 c-105 -100 -118 -115 -108 -133 6 -11 97 -152 203 -314
200 -308 221 -345 213 -376 -3 -13 11 -35 51 -74 l55 -54 105 104 105 105 -51
51 c-43 43 -56 51 -86 50 -28 -1 -40 5 -59 30 l-24 30 68 67 67 67 33 -23 c37
-26 49 -52 31 -73 -11 -13 -4 -24 44 -72 l57 -56 112 109 c62 61 115 114 117
119 2 4 -21 32 -50 62 -39 40 -58 52 -74 48 -24 -6 -54 12 -417 262 -146 101
-268 184 -270 184 -2 -1 -57 -51 -122 -113z m414 -366 c0 -4 -20 -26 -44 -50
l-43 -42 -80 123 c-44 68 -83 129 -87 136 -5 8 51 -26 123 -74 72 -48 131 -90
131 -93z"/>
<path d="M8500 9554 c-41 -38 -50 -53 -50 -80 0 -31 -16 -48 -199 -213 -229
-206 -241 -216 -276 -207 -21 5 -35 -2 -80 -45 l-55 -52 77 -86 c43 -47 142
-157 220 -243 l143 -158 112 102 c62 55 135 121 161 146 l48 44 -58 66 c-33
36 -64 67 -70 69 -6 2 -35 -18 -64 -45 -113 -101 -132 -104 -201 -31 -26 28
-45 55 -42 61 9 15 399 364 427 383 20 12 30 14 46 6 17 -9 28 -3 76 40 31 29
54 55 53 60 -6 16 -200 229 -209 229 -6 0 -32 -21 -59 -46z"/>
<path d="M1290 9272 c-76 -57 -127 -111 -180 -194 -83 -128 -91 -251 -20 -332
37 -42 477 -351 538 -378 81 -36 176 -7 263 83 87 90 152 220 165 334 l6 52
-175 122 c-97 67 -180 121 -184 120 -8 -3 -21 -19 -116 -155 l-57 -81 62 -44
61 -43 28 34 28 33 40 -27 c53 -37 77 -87 61 -127 -7 -16 -26 -41 -43 -55 -48
-40 -77 -29 -254 93 -242 168 -266 194 -237 261 29 71 87 91 150 53 l32 -19
56 82 c31 45 56 85 56 88 0 9 -194 148 -206 148 -5 0 -38 -22 -74 -48z"/>
<path d="M757 8496 c-20 -44 -47 -107 -61 -139 l-26 -58 67 -30 c53 -24 72
-28 85 -20 30 19 56 13 190 -46 l131 -58 -124 -7 c-68 -4 -193 -11 -278 -15
l-153 -8 -49 -109 c-27 -60 -49 -112 -49 -116 0 -3 31 -20 68 -37 60 -26 71
-28 87 -16 11 7 25 13 33 13 16 0 476 -203 520 -230 21 -13 32 -28 32 -43 0
-19 12 -29 70 -54 63 -28 71 -30 79 -15 17 34 111 250 111 256 0 4 -30 21 -66
38 -54 25 -70 29 -85 20 -31 -20 -52 -14 -189 46 -130 57 -131 57 -93 64 21 4
79 7 129 7 50 1 159 4 242 8 l151 6 15 36 c8 20 26 61 39 92 l26 56 -346 154
c-328 146 -394 183 -378 210 6 8 -37 33 -106 62 l-37 15 -35 -82z"/>
<path d="M9305 8420 c-42 -20 -61 -35 -65 -51 -3 -13 -20 -33 -37 -45 -47 -31
-486 -224 -511 -224 -12 0 -30 5 -40 11 -13 8 -32 3 -84 -20 -38 -17 -68 -33
-68 -35 0 -7 132 -301 166 -369 60 -119 119 -165 223 -174 68 -6 118 11 386
131 202 92 251 126 286 201 23 49 24 127 4 186 -31 91 -180 419 -190 418 -5 0
-37 -13 -70 -29z m44 -356 c11 -31 21 -69 21 -84 0 -49 -14 -57 -254 -164
-172 -76 -218 -91 -244 -77 -22 12 -85 126 -80 143 5 13 493 236 519 237 11 1
23 -18 38 -55z"/>
<path d="M1177 7453 c-17 -17 -46 -18 -419 -18 l-402 0 -34 -158 c-19 -88 -30
-162 -26 -167 5 -4 151 -67 324 -140 370 -156 407 -173 415 -199 4 -14 24 -23
81 -36 42 -9 77 -15 78 -13 5 6 56 253 56 270 0 12 -19 20 -72 32 -69 16 -71
16 -85 -4 -8 -11 -25 -20 -38 -20 -26 0 -75 19 -75 29 0 5 10 50 35 159 10 44
115 17 115 -31 0 -8 29 -18 75 -28 l74 -15 12 55 c6 31 22 104 34 162 l22 107
-56 11 c-31 6 -65 14 -76 17 -12 3 -27 -2 -38 -13z m-307 -258 c0 -39 -23
-116 -33 -112 -108 44 -264 112 -271 118 -6 5 59 9 147 9 131 0 157 -2 157
-15z"/>
<path d="M9325 7393 c-310 -23 -332 -23 -361 -8 -29 16 -36 16 -95 -1 -35 -9
-67 -20 -71 -23 -10 -9 64 -281 77 -281 5 0 39 8 75 18 53 15 66 23 70 43 6
26 42 49 77 49 18 0 24 -9 33 -42 6 -24 18 -65 26 -92 8 -27 13 -50 11 -52 -1
-1 -19 -9 -40 -18 -39 -16 -71 -10 -79 15 -5 14 -138 -19 -144 -36 -5 -12 61
-278 75 -301 4 -8 27 -6 76 8 57 15 73 24 87 48 13 23 85 63 367 204 193 97
351 179 351 182 0 3 -19 75 -41 160 l-42 154 -61 -1 c-34 -1 -209 -12 -391
-26z m245 -178 c0 -6 -256 -135 -269 -135 -5 0 -15 27 -23 59 l-15 59 81 6
c44 4 97 8 116 9 63 6 110 7 110 2z"/>
<path d="M9708 6653 l-178 -3 0 -53 c0 -28 3 -69 6 -89 l7 -38 79 0 c70 0 82
-3 103 -25 14 -13 25 -33 25 -45 0 -20 -5 -20 -267 -21 -334 -2 -335 -2 -352
22 -11 16 -26 19 -92 19 l-79 0 0 -92 c0 -51 3 -123 7 -160 l6 -68 72 0 c64 0
74 3 87 22 14 20 27 24 109 30 52 3 187 7 302 7 l207 1 0 -23 c0 -44 -32 -60
-127 -65 l-86 -5 6 -85 c4 -46 8 -87 11 -89 2 -3 90 -1 196 3 l191 7 -6 366
c-4 201 -8 372 -11 379 -2 6 -11 11 -21 10 -10 -1 -98 -4 -195 -5z"/>
<path d="M260 6609 c-51 -70 -73 -143 -78 -255 -7 -168 36 -267 140 -321 44
-22 66 -27 133 -27 143 0 195 41 270 213 60 138 82 178 107 195 45 32 123 11
146 -39 36 -79 -5 -147 -96 -160 l-52 -7 0 -109 0 -110 122 3 123 3 33 55 c75
126 98 304 54 420 -43 110 -129 170 -255 178 -156 9 -218 -32 -292 -194 -82
-179 -99 -209 -126 -221 -77 -37 -148 63 -104 147 19 37 43 50 94 50 l41 0 0
110 0 110 -115 0 -115 0 -30 -41z"/>
<path d="M8970 5868 c0 -3 -11 -67 -25 -143 -14 -75 -24 -139 -21 -142 2 -3
35 -9 74 -15 65 -10 70 -10 90 10 26 26 32 26 75 8 l33 -13 -15 -92 -15 -91
-41 0 c-47 0 -60 7 -69 36 -5 17 -20 23 -77 33 -39 7 -73 11 -75 8 -3 -3 -18
-74 -33 -159 -19 -105 -24 -155 -17 -159 6 -4 40 -12 75 -18 57 -9 67 -9 84 7
16 14 51 18 226 25 113 4 294 10 401 15 l194 7 28 159 c16 88 25 162 21 166
-4 4 -165 67 -358 141 -282 107 -356 139 -380 164 -20 21 -45 34 -70 38 -22 4
-55 9 -72 13 -18 4 -33 4 -33 2z m505 -400 c75 -28 132 -54 128 -59 -4 -4 -73
-9 -151 -11 l-144 -4 7 45 c7 52 14 81 21 81 2 0 65 -23 139 -52z"/>
<path d="M390 5788 c-102 -17 -186 -31 -187 -32 -2 -2 116 -739 120 -743 4 -3
378 55 384 60 1 2 -4 41 -12 88 -8 46 -15 84 -15 85 0 0 -31 -4 -69 -9 -38 -5
-81 -7 -95 -3 -29 7 -64 53 -50 65 9 8 529 91 568 91 11 0 29 -9 41 -20 19
-18 26 -18 90 -8 39 6 72 12 74 14 2 2 -7 73 -20 159 l-24 157 -58 -7 c-74 -8
-107 -20 -107 -40 0 -24 -36 -33 -304 -76 -138 -21 -263 -42 -278 -45 -25 -5
-28 -3 -28 21 0 45 38 72 124 86 71 11 77 14 72 33 -2 12 -8 44 -11 71 -8 63
-15 85 -23 84 -4 -1 -90 -15 -192 -31z"/>
<path d="M1291 4245 l-1 -30 -20 25 -20 25 0 -30 0 -30 -20 25 -21 25 7 -30 7
-30 -23 20 -23 20 5 -28 c5 -26 4 -27 -12 -12 -24 22 -31 19 -20 -10 9 -25 9
-25 -15 -9 -27 18 -31 13 -14 -17 9 -19 9 -19 -10 -10 -26 15 -38 14 -23 -1
20 -20 14 -29 -15 -22 -26 7 -27 7 -10 -10 23 -24 22 -27 -10 -20 l-28 5 24
-20 23 -21 -28 0 c-28 -1 -28 -1 -10 -15 19 -15 19 -15 -2 -15 -28 0 -28 -14
1 -28 22 -10 22 -11 -5 -11 -28 -1 -41 -21 -13 -21 21 0 19 -17 -2 -23 -15 -4
-14 -6 6 -12 l23 -8 -22 -12 -23 -13 22 -7 c21 -6 21 -7 4 -21 -17 -12 -16
-13 7 -14 l25 -1 -25 -21 -25 -21 34 7 35 6 -23 -24 -22 -23 28 5 c28 5 28 5
9 -17 -21 -23 -16 -27 15 -11 22 12 24 7 8 -21 -9 -19 -9 -19 10 -10 28 16 33
14 21 -8 -16 -31 -12 -36 11 -15 l21 19 -7 -35 -8 -35 21 25 21 25 1 -24 c0
-19 3 -21 14 -12 12 10 15 7 18 -16 l3 -28 17 30 17 30 1 -35 1 -35 14 25 14
25 9 -25 10 -25 7 29 8 29 13 -29 c11 -26 12 -27 13 -6 1 12 5 22 9 22 5 0 14
-10 20 -22 10 -22 11 -22 12 7 l0 30 21 -25 21 -25 -6 34 -6 35 24 -23 c24
-23 25 -23 19 -2 -6 23 6 28 25 9 15 -15 15 -3 1 25 -12 21 -12 21 15 4 28
-19 32 -16 16 14 -11 20 3 28 15 9 13 -20 26 -10 14 11 -10 20 -9 21 17 15
l28 -7 -19 22 -20 22 28 -5 c27 -5 27 -5 11 13 -16 18 -16 19 11 19 l27 0 -23
20 -24 19 25 1 c23 1 24 2 7 14 -16 13 -16 14 5 20 l23 7 -25 11 c-25 11 -25
11 -2 15 12 3 22 8 22 13 0 5 -10 11 -22 13 l-23 4 23 8 c28 9 29 25 0 25 -21
0 -21 0 -2 15 18 14 18 14 -5 15 -24 0 -24 0 -5 21 19 22 19 22 -9 17 l-28 -5
23 24 23 25 -30 -8 -30 -7 20 22 19 22 -28 -7 c-26 -6 -27 -5 -17 15 12 21 -1
31 -14 11 -12 -19 -26 -11 -15 9 16 31 12 36 -11 15 l-21 -19 7 35 8 35 -22
-25 -22 -25 4 34 4 33 -18 -23 -19 -24 0 30 0 30 -20 -25 -20 -25 0 30 -1 30
-13 -24 -13 -24 -10 29 -10 29 -7 -30 -8 -30 -13 30 -13 30 -1 -30z"/>
<path d="M8741 4254 c-1 -32 -14 -37 -28 -12 -12 23 -12 23 -13 -7 l0 -30 -21
25 -21 25 6 -35 6 -35 -24 30 -24 30 5 -27 c6 -32 1 -35 -21 -12 -19 18 -21 2
-5 -29 12 -21 12 -21 -15 -4 -28 19 -32 16 -15 -14 9 -19 9 -19 -10 -10 -29
16 -33 14 -21 -9 10 -19 9 -20 -17 -14 l-28 7 20 -23 20 -23 -30 7 c-29 7 -29
7 -11 -13 19 -21 19 -21 -5 -21 -23 -1 -23 -1 -5 -15 19 -15 19 -15 -2 -15
-28 0 -28 -14 1 -28 22 -10 22 -11 -5 -11 -28 -1 -41 -21 -13 -21 8 0 15 -4
15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 10 -10 23 -10 l22 -1
-24 -13 -24 -14 22 -7 c21 -6 21 -7 4 -21 -17 -12 -16 -13 7 -14 l25 -1 -25
-21 -25 -21 34 7 35 6 -24 -26 -25 -25 28 10 c29 11 36 1 12 -19 -21 -17 -12
-24 14 -10 21 11 31 -3 11 -15 -20 -12 -10 -26 10 -15 19 10 20 9 14 -17 l-7
-28 23 20 23 20 -7 -30 -7 -29 22 19 22 20 -5 -28 c-5 -27 -5 -27 13 -11 18
16 19 16 19 -11 l0 -27 20 23 19 24 1 -30 2 -30 12 29 13 29 8 -29 7 -29 10
25 9 25 14 -25 13 -25 4 30 3 30 17 -30 17 -30 1 30 0 30 20 -25 20 -25 -6 35
c-4 28 -3 32 6 18 15 -24 34 -23 27 2 -5 18 -4 18 19 3 24 -16 24 -15 14 12
l-11 28 26 -17 c27 -18 30 -15 14 15 -9 19 -9 19 10 10 30 -17 35 -13 17 14
-16 24 -16 24 10 15 37 -14 39 -13 18 11 l-19 22 30 -7 c29 -7 29 -7 11 13
-19 21 -19 21 10 21 l29 1 -30 16 -30 17 30 6 c27 5 28 6 10 14 -19 8 -19 8 5
18 l25 10 -25 11 c-25 10 -25 10 -2 14 12 3 22 8 22 13 0 5 -10 11 -22 13
l-23 4 23 8 c12 4 22 11 22 16 0 5 -10 9 -22 9 -22 1 -23 1 -4 15 19 14 19 15
-8 15 l-28 0 23 21 24 20 -28 -4 -28 -4 23 24 23 25 -30 -8 -30 -7 20 23 20
23 -28 -7 c-26 -6 -27 -5 -17 14 11 20 -3 30 -15 10 -12 -19 -26 -11 -15 9 14
26 13 38 -2 23 -20 -20 -31 -13 -24 16 l7 27 -21 -25 -20 -25 0 30 c-1 29 -1
29 -15 11 -14 -19 -15 -18 -15 10 l0 29 -20 -25 -20 -25 0 30 c-1 30 -1 30
-13 7 -14 -25 -27 -20 -27 12 -1 17 -3 15 -14 -9 l-13 -30 -10 30 c-9 23 -12
25 -12 9z"/>
<path d="M5373 2119 c-28 -5 -76 -18 -107 -29 l-56 -21 0 -90 0 -89 80 0 c74
0 80 2 80 20 0 27 43 70 69 70 84 0 118 -106 49 -154 -15 -11 -44 -16 -90 -16
l-68 0 0 -65 0 -65 75 0 c94 0 115 -16 115 -88 0 -60 -24 -86 -77 -86 -41 0
-73 31 -73 70 0 23 -2 24 -85 24 l-86 0 3 -91 3 -91 60 -25 c74 -31 206 -42
285 -23 117 28 177 148 140 281 -11 39 -52 83 -89 94 l-23 7 28 18 c56 34 77
66 82 124 2 30 1 71 -2 91 -9 45 -56 99 -104 118 -50 20 -145 27 -209 16z"/>
<path d="M4600 1905 l0 -205 93 0 c65 1 98 5 111 15 27 20 79 19 105 -2 27
-22 38 -105 22 -155 -11 -33 -43 -58 -76 -58 -44 0 -85 43 -85 90 0 18 -7 20
-85 20 l-85 0 0 -94 0 -94 45 -21 c61 -29 128 -42 210 -43 186 0 251 68 249
262 -2 137 -37 201 -128 230 -38 12 -134 9 -183 -6 -22 -6 -23 -4 -23 60 l0
66 63 0 c64 0 97 -17 97 -48 0 -8 26 -12 80 -12 l80 0 0 100 0 100 -245 0
-245 0 0 -205z"/>
<path d="M4683 963 c-13 -2 -32 -16 -43 -30 -17 -22 -20 -40 -20 -133 0 -93 3
-111 21 -134 19 -24 26 -26 100 -26 71 0 82 3 104 25 23 23 25 31 25 130 0 59
-5 115 -11 127 -20 38 -98 56 -176 41z m92 -163 c0 -93 0 -95 -24 -98 -36 -6
-51 22 -51 96 0 79 12 104 48 100 l27 -3 0 -95z"/>
<path d="M5622 955 c-45 -20 -54 -52 -50 -173 4 -133 12 -142 129 -142 114 0
119 6 119 160 0 108 -2 121 -21 138 -35 34 -120 42 -177 17z m112 -71 c9 -23
7 -157 -3 -172 -5 -8 -21 -12 -37 -10 l-29 3 -3 84 c-2 46 -1 90 2 98 8 19 62
17 70 -3z"/>
<path d="M4200 936 c0 -14 5 -28 10 -31 6 -3 10 -50 10 -105 0 -55 -4 -102
-10 -105 -5 -3 -10 -17 -10 -31 0 -23 3 -24 55 -24 52 0 55 1 55 24 0 14 -4
28 -10 31 -6 3 -10 45 -9 93 l1 87 19 -60 c10 -33 25 -86 34 -117 12 -43 21
-58 34 -58 14 0 24 23 49 113 l30 112 1 -82 c1 -45 -3 -85 -9 -88 -5 -3 -10
-17 -10 -31 0 -23 3 -24 65 -24 62 0 65 1 65 24 0 14 -4 28 -10 31 -6 3 -10
52 -10 111 0 63 4 104 10 104 6 0 10 11 10 25 0 24 -2 25 -70 25 l-69 0 -10
-37 c-6 -21 -15 -60 -22 -87 -14 -56 -19 -50 -44 52 l-17 72 -69 0 c-66 0 -69
-1 -69 -24z"/>
<path d="M4910 895 l0 -65 34 0 c32 0 35 3 38 32 2 22 9 34 21 36 17 3 18 -4
14 -95 -3 -54 -8 -113 -12 -130 l-7 -33 61 0 c58 0 61 1 61 24 0 14 -4 28 -10
31 -6 3 -10 50 -10 106 0 78 3 99 14 99 15 0 26 -21 26 -51 0 -17 6 -20 33
-17 32 3 32 3 35 66 l3 62 -151 0 -150 0 0 -65z"/>
<path d="M5240 895 c0 -64 0 -65 28 -65 23 0 30 6 37 32 6 17 16 33 23 36 15
5 18 -193 2 -203 -5 -3 -10 -17 -10 -31 0 -23 3 -24 58 -24 l59 0 -5 131 c-4
109 -2 131 9 127 8 -2 15 -19 17 -36 3 -29 6 -32 38 -32 l34 0 0 65 0 65 -145
0 -145 0 0 -65z"/>
<path d="M5897 823 c-14 -13 -7 -43 12 -53 36 -19 74 17 50 46 -12 15 -51 19
-62 7z"/>
<path d="M5900 685 c-19 -23 -4 -45 29 -45 32 0 48 24 30 46 -15 18 -43 18
-59 -1z"/>
<path d="M4180 590 c0 -7 310 -10 910 -10 600 0 910 3 910 10 0 7 -310 10
-910 10 -600 0 -910 -3 -910 -10z"/>
<path d="M675 483 c5 -16 8 -68 8 -118 0 -49 -3 -102 -8 -117 -7 -28 -7 -28
44 -28 44 0 51 3 51 19 0 11 -4 23 -10 26 -5 3 -10 29 -10 58 0 61 7 55 45
-35 27 -65 30 -68 62 -68 l33 0 0 108 c0 59 3 124 6 145 l7 37 -48 0 c-47 0
-47 0 -40 -27 13 -51 18 -126 7 -119 -5 3 -22 37 -38 76 l-29 70 -44 0 c-43 0
-43 -1 -36 -27z"/>
<path d="M1955 365 l0 -145 38 0 39 0 -7 53 -6 52 31 -52 c27 -45 36 -53 61
-53 22 0 29 5 29 19 0 11 -5 23 -10 26 -6 4 -19 24 -30 45 l-20 39 30 25 c43
36 40 56 -10 56 -27 0 -40 -4 -40 -14 0 -8 -9 -24 -20 -37 l-19 -24 -1 78 0
77 -32 0 -33 0 0 -145z"/>
<path d="M2635 365 l0 -145 37 0 36 0 -1 83 c-2 77 -1 82 19 85 26 4 34 -19
34 -105 l0 -63 35 0 36 0 -6 53 c-15 133 -16 137 -40 153 -20 13 -31 14 -55 6
l-30 -11 0 45 c0 43 -1 44 -32 44 l-33 0 0 -145z"/>
<path d="M4695 365 l0 -145 36 0 c39 0 54 16 38 41 -5 8 -9 67 -9 132 l0 117
-32 0 -33 0 0 -145z"/>
<path d="M5825 500 c-3 -5 -1 -18 5 -29 5 -11 10 -27 10 -35 0 -13 -5 -14 -26
-6 -32 13 -72 3 -84 -20 -15 -28 -12 -153 3 -174 8 -11 25 -18 43 -18 16 1 53
1 83 1 l54 1 -7 31 c-3 17 -6 83 -6 145 l0 114 -34 0 c-19 0 -38 -4 -41 -10z
m10 -175 c0 -57 -1 -60 -25 -60 -22 0 -25 5 -28 39 -5 61 5 88 31 84 20 -3 22
-9 22 -63z"/>
<path d="M6795 365 l0 -145 43 0 c34 0 42 3 42 18 0 10 -5 23 -11 29 -5 5 -9
34 -7 64 2 43 7 55 22 57 28 6 36 -15 36 -95 l0 -73 35 0 34 0 -5 68 c-9 119
-13 132 -41 143 -17 6 -36 6 -55 0 l-28 -10 0 45 c0 43 -1 44 -32 44 l-33 0 0
-145z"/>
<path d="M1178 478 c-24 -20 -27 -52 -17 -151 10 -98 21 -117 66 -117 18 0 23
6 23 25 0 14 -4 25 -10 25 -6 0 -10 28 -10 65 0 37 4 65 10 65 6 0 10 9 10 20
0 11 -4 20 -10 20 -5 0 -10 14 -10 30 0 33 -23 41 -52 18z"/>
<path d="M2519 483 c-22 -6 -28 -51 -19 -157 5 -70 11 -92 26 -103 41 -30 85
7 50 43 -22 21 -23 124 -1 124 8 0 15 9 15 19 0 10 -7 21 -15 25 -8 3 -15 17
-15 31 0 24 -7 27 -41 18z"/>
<path d="M3118 483 c-10 -2 -18 -13 -18 -23 0 -10 -4 -21 -9 -25 -6 -3 -7 -15
-4 -28 3 -12 8 -57 12 -100 6 -82 15 -97 59 -97 17 0 22 6 22 24 0 14 -4 28
-10 31 -5 3 -10 33 -10 66 0 33 4 59 10 59 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 14 -10 30 0 30 -6 33 -42 23z"/>
<path d="M6198 478 c-24 -20 -27 -52 -17 -151 10 -98 21 -117 66 -117 18 0 23
6 23 24 0 13 -5 26 -12 28 -6 2 -13 30 -16 61 -4 46 -1 58 12 63 20 8 21 43 1
50 -8 4 -15 17 -15 30 0 26 -17 31 -42 12z"/>
<path d="M6675 476 c-16 -12 -19 -25 -18 -77 3 -123 12 -169 38 -181 13 -6 31
-8 39 -5 18 7 22 43 4 49 -6 2 -13 30 -16 61 -4 46 -1 58 12 63 20 8 21 40 1
48 -8 3 -15 17 -15 31 0 28 -16 32 -45 11z"/>
<path d="M7279 483 c-23 -6 -27 -36 -21 -148 5 -103 19 -130 64 -123 27 4 37
37 14 50 -22 13 -23 128 -1 128 8 0 15 9 15 20 0 11 -7 20 -15 20 -10 0 -15
10 -15 30 0 30 -6 33 -41 23z"/>
<path d="M9228 478 c-24 -20 -27 -52 -17 -151 10 -98 21 -117 66 -117 18 0 23
6 23 24 0 13 -5 26 -12 28 -6 2 -13 30 -16 61 -4 46 -1 58 12 63 20 8 21 40 1
48 -8 3 -15 17 -15 31 0 27 -17 32 -42 13z"/>
<path d="M969 421 c-21 -17 -24 -27 -24 -95 0 -96 13 -111 89 -110 61 0 86 15
86 50 0 20 -5 24 -29 24 -16 0 -31 -6 -35 -15 -6 -16 -46 -21 -46 -6 0 5 -3
16 -6 25 -5 14 3 16 55 16 l61 0 0 40 c0 56 -34 90 -88 90 -24 0 -49 -8 -63
-19z m89 -53 c3 -13 -3 -18 -22 -18 -20 0 -26 5 -26 21 0 15 5 20 22 17 13 -2
24 -11 26 -20z"/>
<path d="M1281 432 c-13 -4 -15 -38 -2 -46 5 -3 12 -18 16 -33 3 -16 13 -52
22 -81 14 -51 16 -53 46 -50 28 3 32 7 42 53 12 51 17 49 33 -15 7 -29 13 -35
39 -38 30 -3 31 -2 53 75 38 134 38 133 -4 133 -36 0 -36 -1 -37 -42 -1 -24
-4 -54 -8 -68 -7 -21 -10 -15 -22 40 -12 52 -19 66 -36 68 -18 3 -23 -5 -33
-50 -7 -29 -15 -57 -18 -63 -10 -16 -22 48 -16 81 5 30 4 32 -29 36 -19 2 -40
2 -46 0z"/>
<path d="M1625 429 c-31 -19 -35 -29 -35 -106 0 -66 2 -73 28 -93 21 -17 36
-21 69 -17 66 7 73 17 73 112 0 61 -4 86 -15 95 -21 17 -96 23 -120 9z m75
-105 c0 -62 -1 -65 -22 -62 -20 3 -23 10 -26 52 -4 60 2 76 28 76 18 0 20 -6
20 -66z"/>
<path d="M1802 421 c-3 -7 0 -22 6 -32 15 -23 16 -115 2 -124 -5 -3 -10 -15
-10 -26 0 -16 7 -19 44 -19 l45 0 -6 71 c-6 66 -5 70 16 76 16 4 21 13 21 37
l0 31 -56 0 c-39 0 -58 -4 -62 -14z"/>
<path d="M2202 430 c-29 -12 -46 -55 -31 -81 6 -11 30 -29 55 -39 25 -11 44
-26 44 -35 0 -20 -36 -20 -44 1 -9 24 -61 15 -62 -11 -1 -31 13 -42 60 -49 53
-8 93 6 108 39 17 38 -2 70 -54 87 -46 16 -66 48 -29 48 12 0 21 -4 21 -10 0
-5 14 -10 30 -10 25 0 30 4 30 24 0 14 -4 27 -9 30 -18 12 -97 16 -119 6z"/>
<path d="M2903 429 c-26 -7 -33 -15 -33 -34 0 -21 5 -25 30 -25 17 0 33 7 38
17 6 10 16 14 26 10 29 -11 17 -34 -26 -52 -58 -23 -78 -43 -78 -76 0 -38 39
-64 77 -53 16 4 48 6 72 5 l44 -3 -6 38 c-4 22 -7 62 -7 90 0 27 -6 58 -13 68
-15 21 -79 29 -124 15z m64 -157 c-18 -17 -49 -9 -41 11 3 8 15 19 27 24 27
13 37 -11 14 -35z"/>
<path d="M3457 434 c-4 -4 -32 -6 -62 -5 -49 1 -55 -1 -55 -18 0 -11 5 -23 10
-26 6 -3 10 -30 10 -60 0 -30 -4 -57 -10 -60 -5 -3 -10 -15 -10 -26 0 -16 7
-19 44 -19 l44 0 -6 86 -5 86 24 -4 c23 -3 24 -8 29 -83 l5 -80 39 -3 c38 -3
38 -3 31 25 -4 15 -9 58 -12 95 -3 37 -10 71 -16 76 -18 14 -53 23 -60 16z"/>
<path d="M3623 430 c-34 -14 -46 -46 -41 -116 5 -83 20 -99 92 -98 61 0 86 15
86 50 0 20 -5 24 -29 24 -16 0 -31 -6 -35 -15 -7 -20 -42 -19 -50 1 -12 30 -6
34 54 34 l60 0 0 40 c0 72 -64 109 -137 80z m75 -62 c3 -14 -3 -18 -27 -18
-34 0 -34 0 -25 25 8 21 48 16 52 -7z"/>
<path d="M4050 433 c-8 -3 -21 -12 -27 -21 -16 -19 -17 -145 -3 -172 14 -25
72 -35 121 -21 34 10 39 16 39 41 0 27 -3 30 -30 30 -21 0 -30 -5 -30 -16 0
-25 -44 -13 -48 14 -3 21 0 22 52 22 l56 0 0 43 c0 23 -4 47 -8 53 -18 28 -80
42 -122 27z m70 -64 c0 -14 -7 -19 -25 -19 -18 0 -25 5 -25 19 0 11 6 21 13
24 20 7 37 -4 37 -24z"/>
<path d="M4240 433 c-12 -3 -15 -23 -15 -108 l0 -105 43 0 c35 0 42 3 42 19 0
11 -4 23 -10 26 -11 7 -14 64 -4 89 3 9 12 16 20 16 9 0 14 12 14 35 0 31 -2
35 -17 26 -9 -5 -26 -6 -37 -2 -12 4 -28 6 -36 4z"/>
<path d="M4505 415 c-44 -43 -28 -85 39 -108 44 -15 57 -33 30 -44 -10 -4 -22
0 -29 10 -7 10 -24 17 -39 17 -22 0 -26 -4 -26 -30 0 -26 5 -30 42 -40 76 -21
131 3 131 56 0 32 -22 54 -65 68 -18 6 -34 19 -36 29 -4 21 23 23 44 3 21 -22
49 -13 52 16 3 21 -3 28 -29 37 -51 18 -87 13 -114 -14z"/>
<path d="M4839 421 c-21 -17 -24 -27 -24 -95 0 -96 13 -111 89 -110 61 0 86
15 86 50 0 20 -5 24 -29 24 -16 0 -31 -6 -35 -15 -7 -20 -42 -19 -50 1 -12 30
-6 34 54 34 l60 0 0 40 c0 56 -34 90 -88 90 -24 0 -49 -8 -63 -19z m89 -53 c3
-13 -3 -18 -21 -18 -29 0 -37 7 -30 27 8 19 47 12 51 -9z"/>
<path d="M5070 433 c-30 -11 -40 -37 -40 -107 0 -77 4 -87 37 -105 27 -14 61
-14 102 0 25 9 31 16 31 40 0 25 -4 29 -29 29 -16 0 -31 -6 -35 -15 -3 -8 -15
-15 -26 -15 -15 0 -20 7 -20 25 0 24 3 25 55 25 l55 0 0 43 c0 23 -4 47 -8 53
-18 28 -80 42 -122 27z m70 -63 c0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0
15 7 20 25 20 18 0 25 -5 25 -20z"/>
<path d="M5347 433 c-4 -3 -28 -5 -54 -4 l-48 1 0 -145 0 -145 43 0 c35 0 42
3 42 19 0 11 -4 23 -10 26 -5 3 -10 15 -10 25 0 16 4 17 32 9 44 -12 45 -12
68 11 16 16 20 33 20 95 0 62 -4 79 -20 95 -20 20 -50 26 -63 13z m18 -108 c0
-57 -1 -60 -25 -60 -23 0 -25 4 -28 49 -4 62 2 78 30 74 21 -3 23 -8 23 -63z"/>
<path d="M5988 428 c-20 -7 -28 -17 -28 -34 0 -20 5 -24 29 -24 16 0 31 6 35
15 5 13 36 22 36 11 0 -2 3 -11 6 -19 4 -11 -5 -18 -30 -27 -19 -6 -46 -20
-60 -31 -51 -40 -19 -120 41 -104 15 3 49 6 75 5 l48 -1 -6 63 c-9 107 -14
127 -37 143 -24 17 -68 18 -109 3z m80 -134 c-5 -25 -35 -41 -49 -27 -9 9 -9
16 1 28 17 21 52 20 48 -1z"/>
<path d="M6348 428 c-39 -14 -40 -58 -2 -58 15 0 32 7 39 17 7 9 17 13 24 9
23 -15 6 -38 -39 -55 -54 -20 -73 -50 -58 -92 11 -33 41 -45 78 -31 14 5 45 7
69 4 l44 -4 -8 28 c-4 16 -9 59 -12 97 -4 54 -9 70 -26 82 -24 17 -69 18 -109
3z m60 -159 c-38 -25 -53 9 -15 36 21 15 22 14 25 -6 2 -12 -2 -26 -10 -30z"/>
<path d="M7075 433 c-29 -7 -45 -23 -45 -44 0 -25 57 -26 76 -1 10 13 15 14
25 5 19 -19 4 -38 -40 -52 -23 -6 -48 -23 -57 -36 -32 -49 8 -105 64 -89 15 4
48 6 75 5 41 -3 47 0 47 17 0 12 -4 24 -10 27 -5 3 -10 35 -10 71 0 92 -34
119 -125 97z m55 -158 c-7 -8 -19 -15 -26 -15 -22 0 -16 27 9 45 20 14 22 14
25 0 2 -9 -2 -22 -8 -30z"/>
<path d="M7617 434 c-4 -4 -29 -6 -56 -5 -51 1 -68 -12 -50 -40 5 -8 9 -36 9
-64 0 -27 -4 -56 -9 -64 -16 -25 -1 -41 39 -41 l37 0 -1 83 c-1 76 1 82 19 82
15 0 21 -8 23 -30 11 -137 10 -135 49 -135 35 0 35 0 29 38 -3 20 -8 63 -11
94 -4 31 -12 61 -18 66 -18 14 -53 23 -60 16z"/>
<path d="M7781 427 c-15 -7 -28 -26 -34 -47 -11 -41 -3 -126 14 -146 17 -20
71 -27 117 -14 37 10 42 14 42 40 0 26 -4 30 -29 30 -16 0 -31 -6 -35 -15 -3
-8 -15 -15 -26 -15 -15 0 -20 7 -20 25 0 24 3 25 55 25 l55 0 0 40 c0 74 -70
113 -139 77z m77 -59 c3 -13 -3 -18 -22 -18 -20 0 -26 5 -26 21 0 15 5 20 22
17 13 -2 24 -11 26 -20z"/>
<path d="M8205 429 c-31 -19 -35 -29 -35 -106 0 -65 2 -73 26 -92 30 -23 65
-26 113 -10 25 9 31 16 31 40 0 24 -4 29 -25 29 -13 0 -28 -7 -33 -16 -13 -23
-46 -13 -50 14 -3 21 0 22 52 22 l56 0 0 49 c0 31 -5 53 -15 61 -21 17 -96 23
-120 9z m75 -59 c0 -15 -7 -20 -25 -20 -24 0 -34 18 -18 33 15 15 43 7 43 -13z"/>
<path d="M8476 433 c-3 -4 -25 -6 -49 -5 l-42 3 0 -106 0 -105 43 0 c46 0 48
4 31 59 -12 42 1 91 26 91 10 0 15 10 15 29 0 30 -12 46 -24 34z"/>
<path d="M8655 328 l0 -108 38 0 37 0 -6 71 c-7 66 -6 70 15 76 16 4 21 13 21
40 0 28 -3 32 -16 25 -9 -5 -33 -6 -53 -2 l-37 7 1 -109z"/>
<path d="M8816 417 c-23 -23 -26 -35 -26 -92 0 -57 3 -69 25 -90 28 -29 68
-32 119 -11 25 10 33 20 32 37 -1 30 -44 39 -62 14 -17 -22 -48 -15 -52 12 -3
20 2 22 55 25 l58 3 -2 44 c-3 55 -19 72 -76 78 -39 5 -49 2 -71 -20z m87 -41
c7 -19 -1 -26 -30 -26 -22 0 -31 19 -16 33 11 12 41 7 46 -7z"/>
<path d="M9026 419 c-51 -40 -28 -96 47 -114 17 -5 27 -14 27 -26 0 -23 -28
-26 -36 -4 -4 9 -19 15 -36 15 -27 0 -29 -2 -26 -32 3 -31 6 -33 54 -42 75
-12 109 6 109 59 0 38 -3 41 -47 63 -27 12 -48 29 -48 37 0 17 26 20 35 5 3
-5 19 -10 36 -10 24 0 29 4 29 24 0 45 -97 62 -144 25z"/>
<path d="M9372 428 c-23 -12 -36 -43 -30 -75 2 -10 24 -28 51 -41 48 -23 63
-52 27 -52 -11 0 -20 7 -20 15 0 10 -10 15 -31 15 -28 0 -30 -2 -27 -32 3 -28
8 -33 42 -41 53 -13 114 6 122 38 10 42 -5 66 -57 87 -27 11 -49 25 -49 30 0
15 31 21 43 9 6 -6 21 -11 34 -11 18 0 23 6 23 24 0 14 -4 27 -9 30 -20 13
-97 15 -119 4z"/>
<path d="M3780 423 c0 -5 14 -52 32 -106 l32 -98 40 3 40 3 24 80 c40 136 40
125 -8 125 -42 0 -42 0 -37 -32 3 -18 0 -51 -7 -73 l-13 -40 -11 41 c-7 23 -9
55 -6 73 l7 31 -47 0 c-25 0 -46 -3 -46 -7z"/>
<path d="M7940 412 c0 -10 4 -22 9 -27 5 -6 19 -45 31 -87 l23 -78 41 0 41 0
28 98 c15 53 27 100 27 105 0 4 -19 7 -41 7 -41 0 -41 0 -35 -31 3 -18 1 -50
-5 -73 l-12 -41 -13 39 c-8 23 -12 54 -8 73 l6 33 -46 0 c-38 0 -46 -3 -46
-18z"/>
<path d="M5486 255 c-3 -8 1 -21 9 -28 9 -10 10 -18 3 -25 -6 -6 -6 -12 0 -16
19 -11 47 35 43 72 -1 17 -48 15 -55 -3z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
